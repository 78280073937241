
import Heading from "../Heading";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule'
import adaptivePlugin from '@fullcalendar/adaptive'
import {useEffect, useRef, useState} from "react";
import {doGet} from "../../services/rest";
import moment from "moment";
import SlidePane from "react-sliding-pane";
import MyCheckBox from "../MyCheckBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faGear} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import NewEventForm from "./NewEventForm";
import {hasAdmin} from "../../services/menu";
import {useNavigate} from "react-router-dom";
function Calendar() {

    const [calendarEvents, setCalendarEvents] = useState([]);
    const [sidepainOpen, setSidepainOpen] = useState(false);
    const [myCalendars, setMyCalendars] = useState([]);
    const [selectedCalendars, setSelectedCalendars] = useState();
    const allCalendrEvents = useRef([])
    const [adminCalendars, setAdminCalendars] = useState([]);
    const [showNewEventForm, setShowNewEventForm] = useState(false);
    const [currentEvent, setCurrentEvent] = useState()
    const payLoad = useRef()
    const calendarAdmin = hasAdmin("ROLE_CALENDARS")
    const natigation = useNavigate()
    useEffect(() => {
        doGet('calendar/security').then(resp => setAdminCalendars(resp))
    },[])

    const handleMonthChange = async (payload) =>{
        payLoad.current = payload;
        const startDate = moment(payload.start).format("YYYY-MM-DD")
        const endDate = moment(payload.end).format("YYYY-MM-DD")
        const calendars = await doGet(`calendar?startDate=${startDate}&endDate=${endDate}`)
        let combinedCalendarEvents = [];
        let ids = new Set();
        let calendarIds = [];
        for (const calendar of calendars) {
            calendarIds.push(calendar.id);
            if(!selectedCalendars || selectedCalendars?.includes(calendar.id)) {
                for (const event of calendar.calendarEvents) {
                    if (!ids.has(event.id)) {
                        event.backgroundColor = calendar.defaultColor
                        combinedCalendarEvents.push(event);
                        ids.add(event.id);
                    }
                }
            }
        }
        if(!selectedCalendars){
            setSelectedCalendars(calendarIds);
        }
        setCalendarEvents(combinedCalendarEvents);
        allCalendrEvents.current = calendars;
        setMyCalendars(calendars)
    }

    useEffect(() => {
        const combinedCalendarEvents = [];
        for (const calendar of allCalendrEvents.current) {
            if(!selectedCalendars || selectedCalendars?.includes(calendar.id)) {
                for (const event of calendar.calendarEvents) {
                    event.backgroundColor = calendar.defaultColor
                    combinedCalendarEvents.push(event);
                }
            }
        }
        setCalendarEvents(combinedCalendarEvents);
    }, [selectedCalendars]);

    const onCheckBoxChange = (id) =>{
        if (selectedCalendars?.includes(id)) {
            setSelectedCalendars(prevState => prevState.filter(itemId => itemId !== id));
        } else {
            setSelectedCalendars(prevState => prevState.concat(id));
        }
    }

    const showEvent = async event => {
        event.jsEvent.preventDefault()
        const events = await doGet(`calendar/load-event/${event.event.id}`);
        console.log(events)
        events.event.calendar = events.calendars;
        setCurrentEvent(events.event);
        setShowNewEventForm(true)
    }

    const reset = () => {
        setCurrentEvent(undefined)
        setShowNewEventForm(false)
        handleMonthChange(payLoad.current)
    }
    return(<>
        <Heading title={'Events Calender'} icons={[<FontAwesomeIcon onClick={() => setSidepainOpen(true)} cursor={'pointer'} icon={faGear} />]} />
        <div style={{padding:50, backgroundColor: '#fff', zIndex: -1}}>
            <FullCalendar
                schedulerLicenseKey={'0435152620-fcs-1708716914'}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, adaptivePlugin, rrulePlugin]}
                initialView={'dayGridMonth'}
                events={calendarEvents}
                eventTimeFormat={{minute: '2-digit', omitZeroMinute: false, hour: 'numeric'}}
                eventClick={showEvent}
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                }}
                datesSet={handleMonthChange}
            />
        </div>
        <SlidePane
            className={'higher'}
            isOpen={sidepainOpen}
            width={"400px"}
            closeIcon={<FontAwesomeIcon icon={faClose} /> }
            title={`Calendars`}
            from="right"
            onRequestClose={() => setSidepainOpen(false)}
            children={<>
                {adminCalendars.length > 0 && <div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}><button onClick={() =>{
                    setShowNewEventForm(true)
                    setSidepainOpen(false)
                }} className={'submitPost'}>Add Event</button></div> }

                    {myCalendars.map((item, index) => (
                        <div key={index}><MyCheckBox id={item.id} label={item.calendarName} onChange={() => onCheckBoxChange(item.id)} checked={selectedCalendars?.includes(item.id)} /> </div>
                ))}
                {calendarAdmin && <div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}>
                    <button onClick={() => natigation("/calendar-admin")} className={'submitPost'}>Calendar Admin</button></div>
                }
            </>}
        />
        {showNewEventForm && <PopUp
            content={<NewEventForm myAdminCalendars={adminCalendars} currentEvent={currentEvent} reset={reset}/>}
            handleClose={() => {
                setCurrentEvent(undefined)
                setShowNewEventForm(false)}}
        />}
    </>)

}
export default Calendar
