import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faSmile} from "@fortawesome/free-regular-svg-icons";
import { faTrash, faVideo} from "@fortawesome/free-solid-svg-icons";
import AutoExpandingTextArea from "../inputs/AutoExpandingTextArea";
import {doGet, doPostBody, mainUrl, uploadPostImages} from "../../services/rest";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/firebase";
import EmojiPicker from "emoji-picker-react";
import ImageLoadingIndicator from "./ImageLoadingIndicator";
import ImagePreviewViewer from "./ImagePreviewViewer";
import "yet-another-react-lightbox/styles.css";
import PopUp from "../PopUp";
import VideoViewer from "./VideoViewer";


function NewPost({groups, messageEdit, closeMe}) {
    const [message, setMessage] = React.useState({'messageType': 'STANDARD'});
    const [user] = useAuthState(auth);
    const [emojiOpen, setEmojiOpen] = useState(false)
    const [uploadingImages, setUploadImages] = useState(0)
    const [location, setLocation] = useState("all")
    const [isPosting, setIsPosting] = useState(false)
    const [showVideoAdd, setShowVideoAdd] = useState(false)
    const [videoUrl, setVideoUrl] = useState();

    useEffect(() => {
        if(messageEdit){
            setMessage(messageEdit)
        }
    }, [messageEdit])
    const createPost = async event => {
        event.preventDefault()
        if(!message?.message && !message?.messageImages && !message?.video){
            window.alert("Whoops, you need to add some content to submit a post.")
            return
        }
        setIsPosting(true)
        const resp = await doPostBody(`stream/create-post?location=${location}`, message)
        if(!resp.success){
            window.alert(resp.message)
        }else{
            setMessage({'messageType': 'STANDARD', message: ''})
            if(closeMe){
                closeMe()
            }

        }
        setIsPosting(false)

    }

    const emojiClick = (emojiData, event)=>{
        setMessage(prevMessage => {
            return {
                ...prevMessage,
                message: message.message +emojiData.emoji// Append new images
            };
        });
        setEmojiOpen(false)
    }

    const updateTextField = event => {
        setMessage(prevMessage => ({...prevMessage, message: event.target.value}));
    }
    const handleFileUpload = event =>{
        const formData = new FormData()
        for(let i = 0; event.target.files.length > i; i++){
            formData.append('files', event.target.files[i])
        }
        setUploadImages(event.target.files.length)
        uploadPostImages(formData).then(images =>{
            setMessage(prevMessage => ({
                ...prevMessage,
                messageImages: [...(prevMessage.messageImages || []), ...images]  // Append new images
            }));
            setUploadImages(0)
        })
    }

    const addUrl = () =>{
        let regularExpression  = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
        let okVideo = regularExpression.test(videoUrl);
        if(!okVideo){
            regularExpression  = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
            okVideo = regularExpression.test(videoUrl)
        }
        if(okVideo){
            setMessage(prevMessage => {
                return {
                    ...prevMessage,
                    videoLink: videoUrl  // Append new images
                };
            });
            setVideoUrl(undefined)
            setShowVideoAdd(false)
        }else{
            window.alert("A valid Vimeo or Youtube URL must be entered.")
        }
    }

    const removeImageByIndex = (index) =>{
        setMessage(prevMessage => {
            const newImages = (prevMessage.messageImages || []).filter((_, i) => i !== index);
            return {
                ...prevMessage,
                messageImages: [...newImages]  // Append new images
            };
        });
    }

    const deletePost = () => {
        if(window.confirm("Are you sure you want to delete this post?")){
            setIsPosting(true)
            doGet(`stream/delete/${message.id}`).then(resp =>{
                if(resp){
                    closeMe()
                }
            })
        }
    }
    const removeVideoLink = () =>{
        setMessage(prevMessage => {
            return {
                ...prevMessage,
                videoLink: undefined  // Remove video link
            };
        });
    }

    return (
        <>
        <form className="createPostForm" onSubmit={createPost}>
            <div className={'flex-space-between'}>
            <div className="post-header">
                <img src={ mainUrl + 'menu/image/' + user.uid}
                     className={'profile-pic'}/>
                <p className={'post-title'}><i>What's on your mind?</i></p>
            </div>
                {message?.user?.id === user.uid && <FontAwesomeIcon icon={faTrash} onClick={() => deletePost()} style={{cursor: "pointer"}}/>}
            </div>
            <div style={{display: "flex"}}>
            <ImageLoadingIndicator number={uploadingImages} />
                <ImagePreviewViewer images={message?.messageImages} onRemove={(index) => removeImageByIndex(index)}/>
            </div>
            {message.videoLink && <VideoViewer video={message.videoLink} admin={true} removeVideo={() => removeVideoLink()}/> }
            <AutoExpandingTextArea value={message.message} onChange={updateTextField}/>
            <div className={'flex-space-between'} style={{alignItems: 'center'}}>
                <div className={'flex'}>
                    <input
                        type="file"
                        id="fileUpload"
                        style={{display: 'none'}}
                        multiple
                        onChange={handleFileUpload}
                        accept=".png, .jpg, .jpeg"
                    />
                    <label htmlFor={'fileUpload'}  style={{cursor: 'pointer'}}><FontAwesomeIcon icon={faImage}/></label>
                    <div style={{paddingLeft: 10, cursor: 'pointer'}}><FontAwesomeIcon icon={faVideo} onClick={() => setShowVideoAdd(true)}/></div>
                    <div style={{paddingLeft: 10, cursor: 'pointer'}}><FontAwesomeIcon icon={faSmile}
                                                                                       onClick={() => setEmojiOpen(true)}/>
                    </div>

                        <div style={{position: 'absolute'}}>
                            <EmojiPicker open={emojiOpen} onEmojiClick={emojiClick}/>
                        </div>

                </div>
                <div className={'flex'}>
                    <select className={'submitPost'} onChange={(e) => setLocation(e.target.value)}>
                        <option value="All">All</option>
                        {groups.map(group => (<option value={group.id}>{group.locationName}</option>))}
                    </select>
                    {isPosting ? <div style={{
                        border: '16px solid #f3f3f3',
                        borderRadius: '50%',
                        borderTop: '16px solid #e97200',
                        width: '10px',
                        height: '10px',
                        marginLeft: 10,
                        animation: 'spin 2s linear infinite'
                    }}/> : <button className="submitPost" type="submit" style={{marginLeft: 10}}>
                        Post
                    </button>}
                </div>
            </div>
        </form>
            {showVideoAdd && <PopUp
                handleClose={() => setShowVideoAdd(false)}
                content = {<>
                    <div className={'createPostForm'}>
                        <div className={'post-header'}>
                            <p><i>Enter youtube or vimeo URL</i></p>
                        </div>
                        <input type={'text'} className={'fancy-input'} onChange={e => setVideoUrl(e.target.value)}/>
                        <div style={{paddingTop: 20}}>
                        <button className={'submitPost'} onClick={addUrl}>Add</button>
                        </div>
                    </div>
                </>}
            />}
            </>
    )
}

export default NewPost;
