import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import {clearCookie} from "../services/menu";
import {getToken, getMessaging, onMessage} from "@firebase/messaging";
import {doPostNoResponse} from "../services/rest";
import {useContext, useEffect} from "react";
import {NotificationContext} from "../componants/notifications/NotificationContext";
import {useAuthState} from "react-firebase-hooks/auth";


const firebaseConfig = {

    apiKey: "AIzaSyAkR3RLloWP5UaQ4oE8Qg-u7qkDh6Yhj0I",

    authDomain: "gsc-messaging.firebaseapp.com",

    databaseURL: "https://gsc-messaging.firebaseio.com",

    projectId: "gsc-messaging",

    storageBucket: "gsc-messaging.appspot.com",

    messagingSenderId: "1040463536624",

    appId: "1:1040463536624:web:943414d53bd9f1a9eefd35",

    measurementId: "G-V3H8KZ383G"

};


const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const messaging = getMessaging(app)

const useSetupNotifications =() => {
    const { showNotification } = useContext(NotificationContext);
    const [user, loading] = useAuthState(auth);
    useEffect(() => {
        const setupNotifications = async () => {
            console.log("setting up messaging...")
            try {
                // Request permission for notifications
                const permission = await Notification.requestPermission();

                console.log(permission)

                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    // Get the FCM token
                    const token = await getToken(messaging);
                    const formData = new FormData();
                    formData.append('token', token);
                    doPostNoResponse('firebase/token-update', formData);
                    console.log('FCM Token:', token);
                } else {
                    console.log('Notification permission denied.');
                }
                // Handle foreground notifications
                onMessage(messaging, (payload) => {
                    console.log('Foreground Message:', payload.data);
                    try {
                        showNotification(payload.data);
                        console.log('Called showNotification with payload');
                    } catch (error) {
                        console.error('Error when calling showNotificiation', error);
                    }
                });
            } catch (error) {
                console.error('Error setting up notifications:', error);
            }
        }
        if(loading) return;
        if(user){
            setupNotifications()
        }
    }, [user, loading]);

};


const auth = getAuth(app);

const logOut  = () => {
    Notification.requestPermission().then(resp =>{
        if (resp === 'granted') {
            getToken(messaging).then(token => {
                const formData = new FormData();
                formData.append('token', token);
                doPostNoResponse('firebase/delete', formData).then(_ =>{
                    signOut(auth).then(_ =>{
                        clearCookie()
                    })
                });
            })
        }else{
            signOut(auth).then(_ =>{
                clearCookie()
            })
        }
    })
}

const getAuthKey = async () =>{
    return await auth?.currentUser?.getIdToken()
}


export {
    auth,
    logOut,
    getAuthKey,
    messaging,
    useSetupNotifications,
    analytics
}

