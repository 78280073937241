import {doGet} from "./rest";
import Cookies from "universal-cookie";

const cookies = new Cookies(null, {path: '/'})
const loadMenu = async () =>{
    if(localStorage.getItem("menu") !== null){
        return JSON.parse(localStorage.getItem("menu"))
    }
    const res = await doGet("menu")
    const usedIds = []
    const menuItems = []
    const typeIds = []
    res.locationsSet.map(data => {
        data.menuItems.map(item =>{
            if(!usedIds.includes(item.id)){
                usedIds.push(item.id)
                if(item.menuItemTypes.allowMultiple || !typeIds.includes(item.menuItemTypes.id)){
                    menuItems.push(item)
                    if(!item.menuItemTypes.allowMultiple){
                        typeIds.push(item.menuItemTypes.id)
                    }
                }
            }
        })
    })
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_USERS")){
        menuItems.push({icon: 'fa-user', title:'User Admin', menuItemTypes: {jsRoute: '/user-admin'}})
    }
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_DOCUMENTS_REPOSITORIES")){
        menuItems.push({icon: 'fa-documents', title:'Document Repository Admin', menuItemTypes: {jsRoute: '/repository-admin'}})
    }
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_QR_ADMIN")){
        menuItems.push({icon: 'qrcode', title:'Gift Card Admin', menuItemTypes: {jsRoute: '/gift-cards'}})
    }
    if(res.userSecurities.some(obj => obj.securityKey === "ROLE_EMPLOYEE_VEHICLE") || res.userSecurities.some(obj => obj.securityKey === "ROLE_RESIDENT_VEHICLE")){
        menuItems.push({icon: 'vehicle', title:'Vehicles', menuItemTypes: {jsRoute: '/vehicles'}})
    }
    cookies.set("defaultName", res.defaultAppLocation?.locationDisplayName)

    localStorage.setItem("defaultLocation", JSON.stringify(res.defaultAppLocation))
    localStorage.setItem("user", JSON.stringify(res.userSecurities))
    localStorage.setItem("menu", JSON.stringify(menuItems))

    return menuItems;
}

const clearLocalStorage = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("menu")
}

const hasAdmin = (role) => {
    const userLocalStorage = JSON.parse(localStorage.getItem("user"));
    if (userLocalStorage) {
        return userLocalStorage.some(item => item.securityKey === role);
    }
    return false;
}

const getName = () => {
    return cookies.get("defaultName") ?? "GSC"
}

const getDefaultLocation = () => {
    return JSON.parse(localStorage.getItem("defaultLocation"))
}

export {
    loadMenu,
    clearLocalStorage as clearCookie,
    hasAdmin,
    getName,
    getDefaultLocation
}
