import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {doGet, doPostBody} from "../../services/rest";
import {defaultColor} from "../../defaults/AppConfig";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInternetExplorer} from "@fortawesome/free-brands-svg-icons";
import {hasAdmin} from "../../services/menu";
import {faAdd, faTrash} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import NewLinkForm from "./NewLinkForm";

function Links() {
    const {id, title,'*': subpath} = useParams();
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const linksAdmin = hasAdmin("ROLE_LINKS")
    const [showNew, setShowNew] = useState(false)
    useEffect(() =>{
            setLoading(true)
            let url = `links/folder/${id}`;
            if(subpath){
                url += `?folder=${subpath}`;
            }
            doGet(url).then(resp =>{
                setLinks(resp)
                setLoading(false)
            })
    },[subpath, id])
    if(loading){
        return(<>
            <Heading title={title}/>
            <MySpinner />
        </>)
    }
    const navigateRoute = (link) => {
        if(link.folder){
            navigate(`/web-links/${id}/${title}/${link.url}`);
        }else{
            window.open(link.url, "_blank")
        }
    }
    const deleteLink = async (link) => {
        if(link.folder){
            if(window.confirm("Are you sure you want to delete this folder?  It will also delete all containing links.")){
                setLoading(true)
                const data = {id : link.id, categoryId : id}
                if(subpath){
                    data.subPath = subpath
                }
                const resp = await doPostBody('links/admin/folder/delete', data);
                if(resp.success){
                    setLinks(resp.items)
                }else{
                    window.alert(resp.error)
                }
                setLoading(false)
            }
        }else{
            if(window.confirm("Are you sure you want to delete this link?")){
                setLoading(true)
                const data = {id : link.id, categoryId : id}
                if(subpath){
                    data.subPath = subpath
                }
                const resp = await doPostBody('links/admin/link/delete', data);
                if(resp.success){
                    setLinks(resp.items)
                }else{
                    window.alert(resp.error)
                }
                setLoading(false)
            }
        }
    }
    const getIcons = () => {
        if(linksAdmin){
            return [<div onClick={() => setShowNew(true)} style={{cursor: "pointer"}}><FontAwesomeIcon icon={faAdd} /></div>]
        }
        return [];
    }
    return(<>
        <Heading title={title} icons={getIcons()}/>
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {links.map((link, index) => (
                <div key={index} style={{width: '100%', display: 'flex', flexDirection:'row', paddingBottom: 30, cursor: 'pointer', flex: 1}}>
                    <div onClick={() => navigateRoute(link)} style={{width: '100%', display: 'flex', flexDirection:'row', flex: 1}}>
                    <FontAwesomeIcon style={{color: defaultColor, paddingRight:20, fontSize:20}} icon={link.folder ? faFolder : faInternetExplorer} />{link.title}
                    </div>
                    {linksAdmin && <div style={{display: "flex", justifyContent: 'flex-end'}}> <FontAwesomeIcon onClick={() => deleteLink(link)} style={{cursor: 'pointer'}} icon={faTrash} /></div>}
                </div>
            ))}
        </div>
        {showNew && <PopUp
            content={<NewLinkForm id={id} subPath={subpath} update={e => setLinks(e)}/>}
            handleClose={() => setShowNew(false)}
        />}
    </>)
}
export default Links;
