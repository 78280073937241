import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet, doPostBody} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import {ColorPicker, ColorService, useColor} from "react-color-palette";
import "react-color-palette/css";
import MyCheckBox from "../MyCheckBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import AddAdminUser from "./AddAdminUser";

function CalendarAdminForm() {
    const {id} = useParams()
    const [calendar, setCalendar] = useState({})
    const [loading, setLoading] = useState(false)
    const [color, setColor] = useColor("#74141e")
    const [appLocations, setAppLocations] = useState([])
    const [users, setUsers] = useState([])
    const [showAddUser, setShowAddUser] = useState(false)

    useEffect(() => {
        if(id){
            setLoading(true)
            doGet(`calendar-admin/calendar/${id}`).then(resp =>{
                setCalendar(resp)
                setColor(ColorService.convert('hex',resp.defaultColor))
                setLoading(false)
            })
            doGet(`calendar-admin/security-users/${id}`).then(resp =>setUsers(resp))
        }
    }, [id])

    useEffect(() => {
        doGet('calendar-admin/locations').then(resp => setAppLocations(resp))
    }, [])

    useEffect(() => {
        setCalendar(prevCalendar => ({
            ...prevCalendar,
            defaultColor: color.hex
        }))
    }, [color]);

    const save = async () =>{
        setLoading(true)
        const resp = await doPostBody("calendar-admin/save", calendar)
        if(resp.id){
            window.alert("Calendar has been updated successfully.")
        }
        setColor(resp);
        setLoading(false)
    }

    if(loading){
        return (<>
            <Heading title={'Calendar Admin'} />
            <MySpinner />
        </>)
    }

    const onUpdate = event => {
        setCalendar(prevCalendar => ({
            ...prevCalendar,
            [event.target.name]: event.target.value
        }))
    }

    const checkBoxUpdate = () => {
        setCalendar(prevCalendar => ({
            ...prevCalendar,
            publicCalendar: !prevCalendar.publicCalendar
        }))
    }

    const isChecked = id => {
        return calendar?.appLocations?.some(location => location.id === id)
    }

    const toggleCheckBox = (item) => {
        if(calendar.appLocations?.some(location => location.id === item.id)){
            setCalendar(prevCalendar => ({
                ...prevCalendar,
                appLocations: prevCalendar.appLocations.filter(location => location.id !== item.id)
            }))
        }else{
            setCalendar(prevCalendar => ({
                ...prevCalendar,
                appLocations: [...prevCalendar?.appLocations ?? [], item]
            }))
        }
    }

    const updateUsers = data =>{
        setUsers(data)
        setShowAddUser(false)
    }

    const deleteUser = async user =>{
        if(window.confirm("Are you sure you want to delete this users admin for this calendar?")){
            const resp = await doPostBody('calendar-admin/delete-admin-user', user)
            setUsers(resp)
        }
    }


    return(<>
        <Heading title={'Calendar Admin'} />
        <div className={'inner-container'}>
            <div className={'search-container'}>
                <input className={'search-input'} name={'calendarName'} placeholder={'Enter Calendar Name'}
                       value={calendar.calendarName} onChange={onUpdate}/>
            </div>
            <MyCheckBox label={'Public Calendar'} id={'publicCalendar'} checked={calendar.publicCalendar}
                        onChange={checkBoxUpdate}/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flex: 1}}>
                    <p>Select calendar color:</p>
                </div>
                <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                    <ColorPicker color={color} onChange={setColor}/>
                </div>
            </div>
            <div className={'flex'}>
                <div style={{flex: 1}}>
                    <h3>Assigned Locations</h3>
                    {appLocations.map(location => (
                        <MyCheckBox key={location.id} id={location.id} label={location.locationName} checked={isChecked(location.id)} onChange={() => toggleCheckBox(location)} />))}
                </div>
                <div style={{flex: 1}}>
                    { calendar.id && <><h3>Assigned Admins</h3>
                    {users.map(user =>(
                        <div style={{display: "flex", justifyContent: 'space-between', padding: 10}} key={user.id}>{user.gscUsers.displayName}<FontAwesomeIcon onClick={() => deleteUser(user)} cursor={'pointer'} icon={faTrash} /></div>
                    ))}
                    <button onClick={() => setShowAddUser(true)} style={{margin: 20}} className={'submitPost'}>Add User</button></>}
                </div>
            </div>
            <button onClick={save} className={'submitPost'}>Save</button>
        </div>
        {showAddUser && calendar.id && <PopUp
            content={<AddAdminUser calendar={calendar} currentAdmins={users} onUpdate={(data) => updateUsers(data)}/> }
            handleClose={() => setShowAddUser(false)}
        />}
    </>)
}

export default CalendarAdminForm
