import {useEffect, useState} from "react";
import MySpinner from "../MySpinner";
import {doGet} from "../../services/rest";

function GroupSelect({onGroupSelected, closePopUp, groupChecked}) {
    const [groups, setGroups] = useState();

    useEffect(() => {
        doGet('message-admin/groups').then(data => setGroups(data))
    }, [])
    if(!groups){
        return <MySpinner />
    }

    return(
        <>
            {groups.map(group => (
                <div className={"survey-button-container"}>
                    <input className={'survey-radio'} type={'checkbox'} id={group.id} value={group.id} onChange={() => onGroupSelected(group)} checked={groupChecked(group.id)}/>
                    <label className={'checkbox-label'} htmlFor={group.id} >{group.locationName}</label>
                </div>
            ))}
            <button className={'search-button'} onClick={closePopUp}>Close</button>
        </>
    )


}
export default GroupSelect
