import {useState} from "react";
import MySpinner from "../MySpinner";
import {doPost} from "../../services/rest";
import {formatFirstNameLastName} from "../directory/NameFormatters";
import ResidentImages from "../directory/ResidentImages";
import TeamMemberImages from "../directory/TeamMemberImages";

function PersonSearch({onPersonSelect}) {

    const [searchResults, setSearchResults] = useState({});
    const [searchTerm, setSearchTerm] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const search = () => {
        setIsLoading(true)
        const data = new FormData();
        data.append("search", searchTerm);
        doPost('it-admin/user-search', data).then(res => {
            console.log(res)
            setSearchResults(res)
            setIsLoading(false)
        })
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
           search();
        }
    }

    if(isLoading){
        return <MySpinner />
    }



    return(<>
        <div className={'search-container'} style={{alignItems: 'center'}}>
            <input onChange={e => setSearchTerm(e.target.value)} value={searchTerm} className={'search-input'} type={'text'} onKeyDown={handleKeyDown} placeholder={'Enter Search Term'} /><div style={{paddingLeft: 10}}><button onClick={search} className={'submitPost'}>Search</button></div>
        </div>
            {searchResults.residents && <div>
                <h4>Residents</h4>
                {searchResults.residents.map((resident, index) => (
                <div onClick={() => onPersonSelect(resident, 'resident')} key={index} style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 10, cursor: "pointer"}}>
                    <ResidentImages resident={resident} thumb={true} />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flex: 1,
                        paddingLeft: 10
                    }}>
                        <div><b>{formatFirstNameLastName(resident.residentsNew)}</b></div>
                        <div>{resident.homeNumber} {resident.address}</div>
                    </div>
                </div>))}
            </div>}
        {searchResults.teamMembers && <div>
            <h4>Team Members</h4>
            {searchResults?.teamMembers?.map((teamMember, index) => (
                <div onClick={() => onPersonSelect(teamMember, 'team')} key={index} style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 10, cursor: "pointer"}}>
                    <TeamMemberImages teamMember={teamMember} thumb={true} />
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1, paddingLeft: 10}}>
                        <div><b>{teamMember.fname} {teamMember.lname}</b></div>
                        <div>{teamMember.position}</div>
                    </div>
                </div>
            ))}
        </div>}
    </>)
}
export default PersonSearch
