import {hasAdmin} from "../../services/menu";
import ItAdminDashboard from "./ItAdminDashboard";
import StandardDashboard from "./StandardDashboard";

function WorkOrders() {

    if (hasAdmin("ROLE_IT")){
        return <ItAdminDashboard />
    }

    return <StandardDashboard />

}
export default WorkOrders;
